th {
  text-align: center;
}

.header {
  background-color: #8ea9db !important;
}

.sub-header {
  background-color: #d9e1f2 !important;
  font-size: smaller !important;
}

.inner-table-container {
  padding: 0 !important;
}

.grid > * {
  min-width: 0.1% !important;
  min-height: 0.1% !important;
}

table,
td,
tr,
th {
  line-height: 1.32rem;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  border: 1px solid black;
}

@page {
  size: letter portrait;
  margin: 0;
}

@media print {
  html,
  body {
    width: 8.5in;
    height: 11in;
    -webkit-print-color-adjust: exact;
  }
}
